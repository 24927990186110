<template>
  <div class="details">
    <a-page-header title="物料详情" :ghost="false"></a-page-header>
    <div class="details-info bgStyle">
      <h3>物料详情</h3>
      <div class="DI-edit">
        <a-form-model
          class="details-form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          ref="form"
          :validateOnRuleChange="true"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="版本：">
                <a-input disabled="disabled" value="v1.0"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="来源" ref="source" prop="source">
                <a-select v-model="form.source">
                  <a-select-option v-for="item in getSource" :key="item.id">{{
                    item.codeName
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="物料名称：" ref="name" prop="name">
                <a-input v-model="form.name" @pressEnter.stop="cancelEnter" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="物料编码："
                ref="fullCode"
                prop="fullCode"
              >
                <a-input v-model="form.fullCode" disabled="disabled"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="材质：" ref="texture" prop="texture">
                <a-input
                  type=""
                  v-model="form.texture"
                  @pressEnter.stop="cancelEnter"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="规格型号："
                ref="specification"
                prop="specification"
              >
                <a-input
                  v-model="form.specification"
                  @pressEnter.stop="cancelEnter"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item
                label="主工艺："
                ref="mainProcess"
                prop="mainProcess"
              >
                <a-input
                  v-model="form.mainProcess"
                  @pressEnter.stop="cancelEnter"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="分类："
                ref="classificationID"
                prop="classificationID"
              >
                <a-tree-select
                  v-model="form.classificationID"
                  :tree-data="getSort"
                  placeholder="Please select"
                  :replaceFields="replaceFields"
                  @change="SortChange"
                  :disabled="true"
                >
                </a-tree-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="单位：" ref="unitID" prop="unitID">
                <a-select @change="UnitChange" v-model="form.unitID">
                  <a-select-option v-for="item in getUnit" :key="item.id">
                    {{item.codeName}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="描述："
                ref="description"
                prop="description"
              >
                <a-textarea
                  v-model="form.description"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                  @pressEnter.stop="cancelEnter"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col
              :span="12"
              v-for="(attr, index) in form.customeProperties"
              :key="attr.id"
            >
              <a-form-model-item
                :label="attr.name"
                :rules="{
                  pattern: /^\d+(\.{0,1}\d+){0,11}$/,
                  required: attr.required,
                  message: '请输入大于0的数值',
                  trigger: 'blur',
                }"
                :prop="'customeProperties.' + index + '.value'"
                v-if="attr.typeCode == 'Num'"
              >
                <a-input
                  v-model="attr.value"
                  v-if="attr.enabled"
                  style="width: 100%"
                  @pressEnter.stop="cancelEnter"
                />
              </a-form-model-item>
              <a-form-model-item
                :label="attr.name"
                :rules="{
                  required: attr.required,
                  message: '请输入内容',
                  trigger: ['change','blur'],
                }"
                :prop="'customeProperties.' + index + '.value'"
                v-else
              >
                <a-input
                  v-model="attr.value"
                  v-if="attr.typeCode == 'Text' && attr.enabled"
                  @pressEnter.stop="cancelEnter"
                />
                <a-textarea
                  v-model="attr.value"
                  v-if="attr.typeCode == 'LongText' && attr.enabled"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                  @pressEnter.stop="cancelEnter"
                />
                <a-select
                  v-model="attr.value"
                  v-if="attr.typeCode == 'Select' && attr.enabled"
                  style="width: 100%"
                >
                  <a-select-option
                    v-for="(option, idx) in attr.extraProperties.unit"
                    :key="idx"
                    :value="option"
                    >{{ option }}</a-select-option
                  >
                </a-select>
                <a-date-picker
                  v-model="attr.value"
                  v-if="attr.typeCode == 'Date' && attr.enabled"
                  :format="dateFormat"
                  placeholder="日期"
                  style="width: 100%"
                  :valueFormat="dateFormat"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-button type="primary" @click="handleSubmit" html-type="submit"
                >提交</a-button
              >
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import { getLatestCode, createMaterial } from "@/api/material";
import {
  getMaterialClassificationTreeData,
  getMaterialClassification,
} from "@/api/materialclassification";
import {
  getUnitDownListDown,
  getSourceDownList,
} from "@/api/materialclassification";
export default {
  components: {},
  data() {
    return {
      dateFormat: "YYYY-MM-DD",
      getInfo: "",
      getSort: [],
      getUnit: "",
      getSource: [],
      replaceFields: {
        children: "childMaterials",
        key: "id",
        value: "id",
        title: "name",
      },
      form: {
        name: "",
        fullCode: "",
        texture: "",
        specification: "",
        mainProcess: "",
        description: "",
        classificationID: "",
        unitID: null,
        customeProperties: [],
        source: null,
      } ,
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 126,
            message: "名称长度应在1-126字符之间",
            whitespace: true,
            trigger: "blur",
          },
        ],
        fullCode: [{ required: true, message: "请输入内容", trigger: "blur" }],
        classificationID: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        unitID: [{ required: false, message: "请选择内容", trigger: "blur" }],
        specification: [
          { required: false, message: "请输入内容", trigger: "blur" },
        ],
        mainProcess: [
          { required: false, message: "请输入内容", trigger: "blur" },
        ],
        texture: [{ required: false, message: "请输入内容", trigger: "blur" }],
        description: [
          { required: false, message: "请输入内容", trigger: "blur" },
        ],
        source: [{ required: true, message: "请选择内容", trigger: "blur" }],
      },
    };
  },
  created() {
    let _id = this.$route.params.id;
    this.form.classificationID = _id;
    this.getDataInfo(_id);
    this.getAttributes(_id);
    this.getSortDownList();
    this.getUnitDownList("HS_MaterialUnits");
    this.getSourceDownList("HS_Source");
  },
  methods: {
    getAttributes(id) {
      let _this = this;
      getMaterialClassification(id).then((res) => {
        console.log(res.attributes)
        for (var attr of res.attributes) {
          //默认属性
          if (attr.classify == 0) {
            switch (attr.name) {
              case "规格型号":
                if (attr.required) {
                  _this.rules.specification = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              case "主工艺":
                if (attr.required) {
                  _this.rules.mainProcess = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              case "材质":
                if (attr.required) {
                  _this.rules.texture = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              case "描述":
                if (attr.required) {
                  _this.rules.description = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              case "单位":
                if (attr.required) {
                  _this.rules.unitID = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              default:
                break;
            }
          } else if(attr.classify==1&&attr.enabled){
            attr.value = Object.keys(attr.extraProperties).length > 0 &&attr.extraProperties.unit!=undefined? attr.extraProperties.unit[0] : '';
            _this.form.customeProperties.push(attr);
          }
        }
      });
    },
    getDataInfo(id) {
      let _this = this;
      getLatestCode({ classificationID: id })
        .then((result) => {
          _this.form.fullCode = result;
          console.info(_this.form);
        })
        .catch();
    },
    //编辑分类下拉
    getSortDownList(params) {
      const _this = this;
      getMaterialClassificationTreeData(params)
        .then((result) => {
          _this.getSort = result;
        })
        .catch();
    },
    //编辑单位下拉
    getUnitDownList(params) {
      const _this = this;
      getUnitDownListDown(params)
        .then((result) => {
          console.info(result);
          _this.getUnit = result;
          _this.form.unitID = result[0].id
        })
        .catch();
    },
    //来源下拉
    getSourceDownList(params) {
      const _this = this;
      getSourceDownList(params)
        .then((result) => {
          _this.getSource = result;
          for (let s of result) {
            if (s.codeName == "外购") {
              _this.form.source = s.id;
            }
          }
        })
        .catch();
    },
    //分类下拉框取值
    SortChange(value) {
      this.form.classificationID = value;
      this.form.customeProperties=[];
      this.getDataInfo(value);
      this.getAttributes(value);
    },
    //单位下拉框取值
    UnitChange(value) {
      console.log(value);
      this.form.unitID = value;
    },
    //编辑提交数据
    handleSubmit() {
      let _this = this;
      console.info(this.$refs.form);
      this.$refs.form.validate((e) => {
        if (e) {
          createMaterial(_this.form)
            .then((res) => {
              this.$message.success("新增成功");
              this.$router.push({
                path: `/MaterialManagement/edit/${res.id}`,
              });
            })
            .catch();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //阻止enter提交
    cancelEnter(e) {
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.details h3 {
  margin-bottom: 20px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
}

.details-form  ::v-deep.ant-form-item-label {
  display: inline-block;
  width: 165px;
  text-align: left;
}
.details-form ::v-deep.ant-form-item-control-wrapper {
  display: inline-block;
  width: 50%;
}

.o-btn {
  text-align: right;
}
.o-btn .ant-btn:first-child {
  margin-right: 20px;
}

.action-btn a {
  margin-right: 10px;
}

.action-btn a:last-child {
  margin-right: 0;
}

.appendix {
  margin: 20px 0;
}
</style>
